import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

constructor(private httpService: HttpService) { }

getApplicationCount(): Observable<any> {
  return this.httpService.callGetAPI('dashboardManagement/business/dashboard/applicationCount');
}

getWeeklyApplicationCountBasedOnUser(approveId) : Observable<any> {
  return this.httpService.callGetAPI('dashboardManagement/business/dashboard/week?approverId='+approveId);
}

getLoanDispenseAmount() : Observable<any> {
  return this.httpService.callGetAPI('dashboardManagement/business/dashboard/getDisbursementAmount');
}

getApplicationTimeTaken() : Observable<any> {
  return this.httpService.callGetAPI('dashboardManagement/business/dashboard/getTimeTaken');
}

getApplicationTimeTakenPage(page:number) : Observable<any> {
  return this.httpService.callGetAPI(`dashboardManagement/business/dashboard/getTimeTaken?pageNo=${page}&pageSize=10`);
}

getLiveUserCount(): Observable<any> {
  return this.httpService.callGetAPI(`userManagement/business/user/getLiveUserCount`);
}

getUserApiAccessActivity(): Observable<any> {
  return this.httpService.callGetAPI(`dashboardManagement/business/dashboard/userAppCount`);
}

}
