import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { of as observableOf } from 'rxjs';
// import * as cryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { UtilService } from './util.service';
import { getRtlScrollAxisType } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  private currentUserSubject: BehaviorSubject<any>;
  private roleSubject: BehaviorSubject<any>
  public currentUser: Observable<any>;
  public loggedUserId;
  user

  public role

  roleId
  constructor(private router: Router, private httpService: HttpService, private utilService: UtilService) {
    this.currentUserSubject = new BehaviorSubject<any>('');
    this.currentUser = this.currentUserSubject.asObservable();


    if (localStorage['roleID']) {

      this.roleId = +localStorage['roleID']
    }
    else {
      this.roleId = 0
    }
    this.roleSubject = new BehaviorSubject<any>(this.roleId)

  }
  get _roleSubject(): Observable<any> {
    return this.roleSubject.asObservable()
  }
  setRole(id) {
    this.roleId = id
  }

  updateRoleSubject(value: number) {
    this.roleSubject.next(value)
  }

  ngOnInit() {
    this.loadUserName();
  }

  getRole() {


    this.user = this.decryption()

    if (this.user.approvers.length > 0) {
      //  this.updateRoleSubject(this.user.approvers[0].approverLevel)
      let approvers = this.user.approvers.sort(
        (a, b) => {
          return a.approverLevel - b.approverLevel
        }
      )

      this.updateRoleSubject(approvers[0].approverLevel)

    }

  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  get currentLoggedUserId() {
    console.log(this.getDecodedUser(this.decryption()['jwtToken']).userId);
    return this.getDecodedUser(this.decryption()['jwtToken']).userId;
  }
  get currentLoggedCustomerId() {
    console.log(this.getDecodedUser(this.decryption()['jwtToken']).applicationId);
    return this.getDecodedUser(this.decryption()['jwtToken']).applicationId;
  }
  get currentLoggedCustomerMobileNo() {
    console.log(this.getDecodedUser(this.decryption()['jwtToken']).mobileNo);
    return this.getDecodedUser(this.decryption()['jwtToken']).mobileNo;
  }
  get currentLoggedCustomerloanNo() {
    console.log(this.getDecodedUser(this.decryption()['jwtToken']).loanNo);
    return this.getDecodedUser(this.decryption()['jwtToken']).loanNo;
  }
  getUsers(): Observable<any> {
    return observableOf(this.decryption());
  }




  encryption(user) {
    return JSON.stringify(user);
    // return cryptoJS.AES.encrypt(JSON.stringify(user), environment.encryption_key);
  }

  decryption(data = null) {
    if (data === ' ') return;

    const currentUser = (data === null) ? localStorage.getItem(environment.localStorageName) : data;
    let decryptUser = {};
    if (currentUser) {
      decryptUser = JSON.parse(currentUser);
      // decryptUser = cryptoJS.AES.decrypt(currentUser.toString(), environment.encryption_key);
      // decryptUser = JSON.parse((decryptUser as Buffer).toString(cryptoJS.enc.Utf8));
    }
    return decryptUser;
  }

  loadUserName() {
    const token = this.decryption(localStorage.getItem(environment.localStorageName))['jwtToken'];
    if (!token || String(token).length === 0) return;
    const decoded = this.getDecodedUser(token);
    this.currentUserSubject.next(decoded);
    this.loggedUserId = decoded.userId;
  }

  getDecodedUser(token) {
    return jwt_decode(token)
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
