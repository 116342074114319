import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { HttpClient, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface Response {
  statusCode: string;
  statusMessage: string;
  data: {}[];
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public loggedUserId;
  constructor(
    private http: HttpClient,
    private router: Router) { }
    
    callGetAPI(url: string): Observable<Response> {
      return this.http.get<any>(environment.apiUrl + url)
      .pipe(map(
        data => {
          return data;
        }
        ));
      }

    callGetImageAPI(url: string): Observable<Blob> {
      return this.http.get<any>(environment.apiUrl + url, {responseType: 'blob' as 'json'})
        .pipe(map(
          data => {
            return data;
          }
        ));
      }
    
    
  callPostAPI(url: string, parameters: any): Observable<Response> {
     return this.http.post<any>(environment.apiUrl + url, parameters)
        .pipe(map(
          data => {
            return data;
          }
        ));
    }

  
 callGetExcelAPI(url: string): Observable<Blob> {
    return this.http.get<any>(environment.apiUrl + url, {responseType: 'blob' as 'json'})
    .pipe(map(
      res => {
        return res;
      }
    ));
    }


callPostExcelAPI(url: string,date): Observable<Blob> {
  return this.http.post<any>(environment.apiUrl + url,date,{responseType: 'blob' as 'json'})
    .pipe(map(
      res => {
        return res;
      }
    ));
   }

callPutAPI(url: string, parameters: any): Observable<Response> {
  return this.http.put<any>(environment.apiUrl + url, parameters)
    .pipe(map(
      data => {
        return data;
      }
    ));
}
callDeleteAPI(url: string): Observable<Response> {
  return this.http.delete<any>(environment.apiUrl + url)
    .pipe(map(
      data => {
        return data;
      }
    ));
}

callPostFileAPI(url: string, data: any): Observable<any> {

  const formdata: FormData = new FormData();
  formdata.append('file', data.file);
  return this.http.post<any>(environment.apiUrl + url, formdata)
    .pipe(map(
      data => {
        return data;
      }
    ));
}

callFileToStorage(url: string, data: any): Observable<any> {

  const formdata: FormData = new FormData();
  formdata.append('file', data.file);
  formdata.append('applicationNo', data.applicationNo);
  formdata.append('appId', data.appId);
  formdata.append('documentType', data.documentType);
  formdata.append('documentId',data.documentId)
  return this.http.post<any>(environment.apiUrl + url,formdata)
    .pipe(map(
      data => {
        return data;
      }
    ));

  // const formdata: FormData = new FormData();
  // formdata.append('file', data.file);
  // formdata.append('applicationNo', data.applicationNo);
  // formdata.append('appId', data.appId);
  // formdata.append('documentType', data.documentType);
  // //console.log('file',file.file)
  // return this.http.post(environment.apiUrl + url,formdata, {
  //   reportProgress: true,
  //   observe: 'events'
  // })
  //   .pipe(map(event => {
  //     switch (event.type) {
  //       case HttpEventType.UploadProgress:
  //         console.log('Upload progress', Math.round(event.loaded * 100 / event.total));
  //         return Math.round(event.loaded * 100 / event.total);
  //         // file.progress = Math.round(event.loaded * 100 / event.total);
  //         break;
  //       case HttpEventType.Response:
  //         return event;
  //     }
  //   }));
}

  // const req = new HttpRequest('POST',environment.apiUrl + url, formData, {
  //   reportProgress: true,
  //   responseType: 'text'
  // });
  // return this.http.request(req);

}
