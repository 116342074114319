import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  masterList = [];
constructor(
  private httpService: HttpService,
) {
}


fetchMasterList() {
  this.httpService.callGetAPI('masterManagement/business/master/meta').subscribe(data => {
    if (data.statusCode === '200' && data.statusMessage === 'Success')
    this.masterList = data.data;
  });
}

getMasterListOf(masterName) {
  return this.masterList[masterName];
}

}
